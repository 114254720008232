import React from 'react';
import HomeTemplate from '../../templates/HomeTemplate';

const Page: React.SFC = (props) => (
  <HomeTemplate
    {...props}
    header="The best internal documentation software"
    subheader="Easy and powerful internal documentation designed for your team, from the non-technical to the tech-savvy."
    metaTitle="The best internal documentation for teams - Slab"
    metaDescription="Easy and powerful internal documentation designed for everyone on your team, from the non-technical to the tech-savvy."
    hideNavbarLinks
  />
);

export default Page;
